import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Col, Row } from 'reactstrap'
import type { TimeRecordTypeModel } from '@evelia/common/types/timeRecordTypes'

import TimeRecordStatusButton from '../../components/TimeRecords/TimeRecordStatusButton'
import { getSelectableTimeRecordTypesByIds } from '../../selectors/timeRecordSelectors'
import useLeimaaBoard from './useLeimaaBoard'

interface LeimaaTimeRecordTypesListProps {
  createTimeRecord: (timerecordTypeId: number) => void
  currentTimeRecordType: TimeRecordTypeModel
  employeeTimeRecordTypes: TimeRecordTypeModel[]

}

const LeimaaTimeRecordTypesList = ({ createTimeRecord, currentTimeRecordType, employeeTimeRecordTypes }: LeimaaTimeRecordTypesListProps) => {
  const { board } = useLeimaaBoard()

  const selectableTimeRecordTypeIds = useMemo(() => [
    board.defaultTimeRecordTypeId,
    ...employeeTimeRecordTypes
      .filter(employeeTimeRecordType => board.timeRecordTypeIds.includes(employeeTimeRecordType.id))
      .map(employeeTimeRecordType => employeeTimeRecordType.id)
  ]
  , [board.defaultTimeRecordTypeId, board.timeRecordTypeIds, employeeTimeRecordTypes])

  // @ts-expect-error selectors are not typed
  const timeRecordTypes = useSelector(state => getSelectableTimeRecordTypesByIds(state, selectableTimeRecordTypeIds))

  return (
    <Row className='px-3'>
      {timeRecordTypes.map(timeRecordType => (
        <Col
          xs={6}
          xl={4}
          key={timeRecordType.id}
          className='my-1 g-1'
        >
          <TimeRecordStatusButton
            timeRecordType={timeRecordType}
            disabled={timeRecordType.id === currentTimeRecordType.id}
            className='w-100 py-2'
            testId='bigBrotherStatusButton'
            onClick={() => createTimeRecord(timeRecordType.id)}
          />
        </Col>
      ))}
    </Row>
  )
}

export default LeimaaTimeRecordTypesList
