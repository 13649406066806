import React, {
  useContext,
  useMemo,
  useState
} from 'react'
import type {
  ReactNode
} from 'react'

type Updater = {
  addSelection: (id: number) => void
  removeSelection: (id: number) => void
  reset: () => void
}
const SelectContext = React.createContext<Set<number> | null>(null)
const SelectUpdaterContext = React.createContext<Updater | null>(null)

export const useSelectContext = () => {
  const context = useContext(SelectContext)
  if(context == null) {
    throw new Error('useSelectContext was used outside of its Provider')
  }
  return context
}
export const useSelectUpdaterContext = () => {
  const context = useContext(SelectUpdaterContext)
  if(context == null) {
    throw new Error('SelectUpdaterContext was used outside of its Provider')
  }
  return context
}

interface SelectProviderProps {
  children: ReactNode
}

export const SelectProvider = ({ children }: SelectProviderProps) => {
  const [selections, setSelections] = useState(() => new Set<number>())

  const updaters = useMemo(() => {
    const addSelection = (id: number) => {
      setSelections(state => {
        const newState = new Set([...state])
        newState.add(id)
        return newState
      })
    }
    const removeSelection = (id: number) => {
      setSelections(state => {
        const newState = new Set([...state])
        newState.delete(id)
        return newState
      })
    }
    const reset = () => setSelections(() => new Set())
    return { addSelection, removeSelection, reset }
  }, [])

  return (
    <SelectContext.Provider value={selections}>
      <SelectUpdaterContext.Provider value={updaters}>
        {children}
      </SelectUpdaterContext.Provider>
    </SelectContext.Provider>
  )
}
